jQuery.migrateMute = true;
tooltipSettings = {
    track: true,
	content: function() {
		var data = $(this).attr('title');
		var parts = data.split('|');

        if (parts.length > 1)
        	return '<div><h4>' + parts[0] + '</h4> ' + parts[1] + '</div>';
        else
            return '<div>' + parts[0] + '</div>'
	}
};
